<template>
	<div>
		<div style="background-color: #F9F9F9">
			<div class="logo_body flex-center">
				<div class="flex">
					<div class="ranking_list flex-center">运动员排名</div>
					<div class="ranking_list_all flex-center">队伍排名</div>
				</div>
			</div>
			<div class="tabs_body flex-center">
				<div class="tabs_item pointer_s" v-for="(item,index) in tabsList" :key="index"
						 :class="item.choose ? 'choose' : ''" @click="tabsClick(index)">
					{{ item.name }}
				</div>
			</div>
			<div class="flex-center" style="padding: 24px 0">
				<el-input
					class="input_search"
					size="small"
					placeholder="请输入搜索内容"
					suffix-icon="el-icon-search"
					v-model="searchV">
				</el-input>
			</div>
		</div>

		<div class="table_diy_body">
			<div class="header_title">{{ tabsList[chooseIndex].name }}积分排名</div>
			<div class="line82"></div>
			<el-table
				:data="tableData"
				header-cell-style="border-top: 1px solid #E8E8E8;border-bottom: 1px solid #E8E8E8;padding:26px 20px;"
				cell-style="padding-left: 20px;padding-right: 20px;"
				style="width: 100%;">
				<el-table-column
					prop="index"
					label="本周排名"
					min-width="80">
					<template slot="header">
						<div class="rank_c">本周排名</div>
						<div class="rank_e">POSITION</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"
					label="上周排名"
					min-width="80">
					<template slot="header">
						<div class="rank_c">上周排名</div>
						<div class="rank_e">LAST WEEK</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="address"
					min-width="80"
					label="姓名">
					<template slot="header">
						<div class="rank_c">姓名</div>
						<div class="rank_e">NAME</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="address"
					min-width="130"
					label="国籍/地区">
					<template slot="header">
						<div class="rank_c">国籍/地区</div>
						<div class="rank_e">NATIONALITY/REGION</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="address"
					min-width="90"
					label="总分">
					<template slot="header">
						<div class="rank_c">总分</div>
						<div class="rank_e">TOTAL SCORE</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="address"
					min-width="80"
					label="参赛场次">
					<template slot="header">
						<div class="rank_c">参赛场次</div>
						<div class="rank_e">NUMBERS</div>
					</template>
				</el-table-column>
				<el-table-column
					prop="address"
					min-width="100"
					label="平均分">
					<template slot="header">
						<div class="rank_c">平均分</div>
						<div class="rank_e">AVERAGE SCORE</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="pagination" style="margin-bottom: 20px">
			<el-pagination @current-change="currentChange" :page-size="per_page" background layout="prev, pager, next"
										 :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	name: 'integral',
	data() {
		return {
			per_page: 1,
			total: 0,
			tableData: [
				// {name: '11', address: '是的是的', index: 1},
				// {
				// 	name: '说的就是放假撒上课的哈萨克你',
				// 	address: '是的是的',
				// 	index: 2
				// }
			],
			tabsList: [
				{
					name: '职业男子',
					choose: true
				},
				{
					name: '职业女子',
					choose: false
				},
				{
					name: '业余男子',
					choose: false
				},
				{
					name: '业余女子',
					choose: false
				},
				{
					name: '全国青少年排名榜',
					choose: false
				},
				{
					name: '精英赛事排名',
					choose: false
				},
			],
			searchV: '',
			chooseIndex: 0
		}
	},

	mounted() {
		this.$route.meta.pathName = '积分榜'
	},

	methods: {
		tabsClick(index) {
			this.chooseIndex = index
			if (this.tabsList[index].choose) return
			this.tabsList.forEach(item => {
				item.choose = false
			})
			this.tabsList[index].choose = true
		},
		currentChange() {

		}
	}
}
</script>

<style lang="less" scoped>
.logo_body {
	.ranking_list {
		width: 214px;
		height: 50px;
		background: #212F82;
		font-size: 20px;
		color: #FFFFFF;
		margin-top: 20px;
	}

	.ranking_list_all {
		width: 214px;
		height: 50px;
		background: #B4375F;
		font-size: 20px;
		color: #FFFFFF;
		margin-top: 20px;
	}
}

.tabs_body {
	margin-top: 37px;
	padding-bottom: 20px;
	border-bottom: 1px solid #BEBEBE;

	.tabs_item {
		padding: 9px 20px;
		font-size: 16px;
		border-radius: 45px;
		margin-right: 22px;
	}

	.choose {
		background: #212F82;
		color: #FFFFFF;
	}
}

.input_search {
	width: 349px;
	background-color: rgba(0, 0, 0, 0);

	::v-deep input::-webkit-input-placeholder {
		// color: red;
		font-size: 14px;
	}

	//输入框颜色
	:deep(.el-input__inner) {
		background-color: rgba(0, 0, 0, 0) !important;
	}


}

/deep/ .el-input__inner {
	border-radius: 29px !important; /* 设置圆角大小 */
	border: 1px solid #212F82;
	height: 40px;
}

/deep/ .el-input__icon {
	line-height: 40px;
	color: #212F82;
}

.table_diy_body {
	width: 1060px;
	display: flex;
	flex-direction: column;
	padding: 24px 27px;
	background-color: #FFFFFF;
	border-top: 5px solid #212F82;

	.header_title {
		font-size: 20px;
		color: #333333;
		line-height: 23px;
		margin-bottom: 20px;
	}

	.rank_c {
		white-space: nowrap;
		font-size: 16px;
		color: #333333;
		line-height: 19px;
	}

	.rank_e {
		white-space: nowrap;
		font-size: 10px;
		color: #333333;
		line-height: 12px;
		font-weight: 400;
		margin-top: 4px;
	}
}


/deep/ .el-pager li {
	font-weight: normal;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #212F82;
	color: #fff;
}

.pagination {
	display: flex;
	justify-content: center;
	//margin-top: 29px;

}

/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
	border-bottom: none;
}

/deep/ .el-table--border::after, .el-table--group::after, .el-table::before {
	content: '';
	position: absolute;
	background-color: #FFFFFF;
	z-index: 1;
}
</style>
